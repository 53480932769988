import React, { useEffect } from "react";

// import {useHistory} from "react-router-dom"
import { useHistory } from "react-router-dom";

import { useNavigate } from "react-router-dom";

function Logout() {
  useEffect(() => {
    Logout();
  });

  const Logout = () => {
    // const navigate = useNavigate();
    // localStorage.clear();
    // navigate('/Login');
  };
  return <></>;
}

export default Logout;
