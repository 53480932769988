import React, { useState, useEffect, useContext } from "react";
import dashboard from "../assets/img/dashboard.png";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import Loader from "react-js-loader";
import percent from "../assets/img/play.png";
import { useNavigate } from "react-router-dom";

import {
  getActiveSession,
  getSessions,
  stopSessions,
  cancelActiveSession,
} from "../services/services/account-services";
import AppContext from "../context/AppContext";
// import { Tab, Tabs } from "react-bootstrap";

function Dashboard() {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const context = useContext(AppContext);

  const [data, setData] = useState();
  const [chkActiveSession, getChkActiveSession] = useState({});
  const [sessionId, setSessionId] = useState("");
  const [order, setOrder] = useState([]);
  const [activeSession, setActiveSession] = useState([]);
  const [sessionStatus, setSessionStatus] = useState("");

  // useEffect(() => {
  //   // return clearInterval(interMat);
  //   if (chkActiveSession == null) {
  //     addToast("Click on Start button to Add INJAZ BOT information to start", {
  //       appearance: "error",
  //       autoDismiss: true,
  //       autoDismissTimeout: 5000,
  //     });
  //   }
  // }, [chkActiveSession]);

  useEffect(() => {
    GetActiveSession();

    let interMat = setInterval(() => {
      GetActiveSession();
    }, [5000]);

    return () => clearInterval(interMat);
  }, []);

  const GetActiveSession = () => {
    // let token = localStorage.getItem("AuthentificationToken");
    // axios
    //   .get(` https://ijzbot-api.ibt-learning.com/api/v1/Session/GetActiveSession`, {
    //     headers: {
    //       Authorization: "Bearer " + token,
    //     },
    //   })
    const { setIsAuthenticated, setIsLoading } = context;
    getActiveSession()
      .then((resp) => {
        getChkActiveSession(resp.data.data, "getActiveSession");
        if (localStorage.getItem("AuthentificationToken")) {
          setIsAuthenticated(true);
        }
        setActiveSession(resp.data.data);
        setSessionId(resp.data.data.sessionId);
        console.log("Status", resp.data.data.sessionStatus);
        setSessionStatus(resp.data.data.sessionStatus);
        let seId = resp.data.data.sessionId;
   
        getSessions(seId)
          .then((resp) => {
            console.log(resp.data.data[0].orders, "getsession123");
            setOrder(resp.data.data[0].orders);
          })
          .catch((error) => {});
      })
      .catch((error) => {});
  };

  const stopSession = () => {
    // let token = localStorage.getItem("AuthentificationToken");
    // axios
    //   .get(` https://ijzbot-api.ibt-learning.com/api/v1/Session/StopActiveSession`, {
    //     headers: {
    //       Authorization: "Bearer " + token,
    //     },
    //   })
    const { setIsAuthenticated, setIsLoading } = context;
    setIsLoading(true);
    stopSessions()
      .then((resp) => {
        setIsLoading(false);

        if (!resp.data.isError) {
          addToast(`${resp.data.message}`, {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        } else {
          addToast(`${resp.data.message}`, {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const cancelSession = () => {
    // let token = localStorage.getItem("AuthentificationToken");
    // axios
    //   .get(` https://ijzbot-api.ibt-learning.com/api/v1/Session/CancelActiveSession`, {
    //     headers: {
    //       Authorization: "Bearer " + token,
    //     },
    //   })
    const { setIsAuthenticated, setIsLoading } = context;
    setIsLoading(true);
    cancelActiveSession()
      .then((resp) => {
        setIsLoading(false);

        if (!resp.data.isError) {
          addToast(`${resp.data.message}`, {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          setIsLoading(false);

          addToast(`${resp.data.message}`, {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        }
      })
      .catch((error) => {});
  };

  return (
    <>
      <section className="dashboard-landing-pnl container-fluid">
        <div className="row">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h1>Manage your Bot Session</h1>
                <div className="flex-div run-btns-pnl">
                  <ul className="dashboard-list">
                    <li>
                      {sessionStatus === 0 ? (
                        <>
                          <a
                            href="#"
                            className="reg-btn blue full"
                            onClick={() => {
                              navigate("/bot");
                            }}
                          >
                            Running
                          </a>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span>Symbol {activeSession.sessionSymbol}</span>
                            <span>Safty Order {activeSession.maxSo}</span>
                            <span
                            // style={{ whiteSpace: "nowrap" }}
                            >
                              Base Amount {activeSession.baseOrderAmount} USDT
                            </span>
                            <span
                            // style={{ whiteSpace: "nowrap" }}
                            >
                              Max Amount {activeSession.maxUsageAmount} USDT
                            </span>
                            <span
                            // style={{ whiteSpace: "nowrap" }}
                            >
                              Profit {activeSession.takeProfitPercentage} %
                            </span>
                          </div>
                        </>
                      ) : sessionStatus === 1 ? (
                        <>
                          <a
                            href="#"
                            className="reg-btn blue full"
                            onClick={() => {
                              navigate("/bot");
                            }}
                          >
                            Stopped
                          </a>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span>Symbol {activeSession.sessionSymbol}</span>
                            <span>Safty Order {activeSession.maxSo}</span>
                            <span >
                              Base Amount {activeSession.baseOrderAmount} USDT
                            </span>
                            <span >
                              Max Amount {activeSession.maxUsageAmount} USDT
                            </span>
                            <span >
                              Profit {activeSession.takeProfitPercentage} %
                            </span>
                          </div>
                        </>
                      ) : sessionStatus === 2 ? (
                        <a
                          href="#"
                          className="reg-btn blue full"
                          onClick={() => {
                            navigate("/bot");
                          }}
                        >
                          Start
                        </a>
                      ) : (
                        <>
                          <a
                            href="#"
                            className="reg-btn blue full"
                            onClick={() => {
                              navigate("/bot");
                            }}
                          >
                            Start
                          </a>
                          <img
                            style={{ cursor: "pointer" }}
                            height={100}
                            src={percent}
                            onClick={() => {
                              navigate("/bot");
                            }}
                          />
                        </>
                      )}
                    </li>
                    {chkActiveSession === null ? null : (
                      <>
                        {" "}
                        <li>
                          <a
                            href="#"
                            className="reg-btn blue full"
                            onClick={stopSession}
                          >
                            Stop
                          </a>
                          <button>
                            <i className="fa fa-stop"></i>
                          </button>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="reg-btn blue full"
                            onClick={cancelSession}
                          >
                            Cancel
                          </a>
                          <button className="blue">
                            <i className="fa fa-close"></i>
                          </button>
                        </li>
                        <li className="volume-list">
                          <div>
                            <p className="reg-btn blue full">
                              Total volume bought{" "}
                              <span>
                                {chkActiveSession?.totalVolBought} USDT
                              </span>
                            </p>
                            <p className="reg-btn blue full">
                              Total volume sold{" "}
                              <span>{chkActiveSession?.totalVolSold} USDT</span>
                            </p>
                          </div>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
                {/* <p>
                  So lets begin the journey of trust together and set new
                  milestones of trust.
                </p> */}
              </div>
              {chkActiveSession == null ? (
                <></>
              ) : (
                <>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="full-div table-contnr">
                      <table>
                        <caption>Orders</caption>
                        <thead>
                          <tr>
                            <td>ID</td>
                            <td>SIDE</td>
                            <td>ORDER TYPE</td>
                            <td>STATUS</td>
                            <td>RATE</td>
                            <td>AMOUNT</td>
                            <td>VOLUME</td>
                            <td>CREATED</td>
                          </tr>
                        </thead>
                        <tbody>
                          {order?.reverse().map((ord, ind) => {
                            return (
                              <tr>
                                <td>{ord.orderId}</td>
                                <td>
                                  {ord.orderSide == 1
                                    ? "Sell"
                                    : ord.orderSide == 2
                                    ? "Buy"
                                    : null}
                                </td>
                                <td>
                                  {ord.orderType == 1
                                    ? "Base"
                                    : ord.orderType == 2
                                    ? "Safety"
                                    : ord.orderType == 3
                                    ? "Profit"
                                    : null}
                                </td>
                                <td>
                                  {ord.orderStatus == 0
                                    ? "New"
                                    : ord.orderStatus == 2
                                    ? "Filled"
                                    : ord.orderStatus == 3
                                    ? "Cancelled"
                                    : null}
                                </td>
                                <td>{ord.orderLimitRate}</td>
                                <td>{ord.orderAmount}</td>
                                <td>{ord.orderAmountUsdt} USDT</td>
                                <td>
                                  {ord.createdAt.split("T")[0]}{" "}
                                  {ord.createdAt.split("T")[1].split(".")[0]}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Dashboard;
