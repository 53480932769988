import React, { useContext } from "react"
import LoadingOverlay from "react-loading-overlay";
import AppContext from "../context/AppContext";


function Loader(props){

    const context = useContext(AppContext);
    const { isLoading } = context;

    return (
      <LoadingOverlay
        active={isLoading}
        spinner
        text='Please wait...'
        styles={{
          wrapper: {
            // position:'fixed',
            top: 0,
            left: 0,
          width: "100%",
          height: "100%",
          overflow: isLoading ? 'hidden' : 'unset'
        }
        }}
            >
  { props.children }
          </LoadingOverlay >
            )
    
}
export default Loader;
