import React, { useContext, useEffect, useState } from "react";
import logo from "../assets/img/logo.png";
import profilepic from "../assets/img/profile-pic.png";
import google from "../assets/img/google-plus.png";
import fb from "../assets/img/facebook.png";
import music from "../assets/img/music.png";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "react-bootstrap";

import axios from "axios";
import AppContext from "../context/AppContext";
import { Link, useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  const context = useContext(AppContext);

  const [createaccountmodalshow, CreateaccountModalShow] =
    React.useState(false);
  const [choosepasswordmodalshow, ChoosepasswordModalShow] =
    React.useState(false);
  const [accountmodal, AccountModalShow] = React.useState(false);
  const [loginmodalshow, loginModalShow] = React.useState(false);
  const [passwordmodalshow, passwordModalShow] = React.useState(false);
  const [signinModal, SignInModalShow] = React.useState(false);

  const [uploadmodalshow, uploadModalShow] = React.useState(false);
  const [awaenmodal, awaenModalShow] = React.useState(false);

  function uploadmodalStatus() {
    awaenModalShow(true);
    uploadModalShow(true);
  }

  function uploadclosemodalStatus() {
    awaenModalShow(false);
    uploadModalShow(false);
  }

  function modalStatus() {
    SignInModalShow(true);
    loginModalShow(true);
  }

  function modalStatus2() {
    AccountModalShow(true);
    CreateaccountModalShow(true);
  }

  function loginmodalStatusclose() {
    SignInModalShow(false);
    loginModalShow(false);
  }

  function accountmodalStatus() {
    AccountModalShow(false);
    CreateaccountModalShow(false);
  }

  const [firstName, setFirstName] = useState();
  const [lastname, setLastname] = useState();
  const [email, setemail] = useState();
  const [password, setPassword] = useState();
  const [logEmail, setLogEmail] = useState();
  const [logPassword, setLogPassword] = useState();

  const Logout = () => {
    const { setIsAuthenticated } = context;
    setIsAuthenticated(false);
    localStorage.clear();
    navigate("/login");
  };

  return (
    <>
      {/* <nav id="main-navigation" className="container-fluid "> */}
      <nav id="main-navigation" className="container-fluid login-nav">
        <div className="row">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-sm-12 col-md-12 col-12">
                <div className="flex-div">
                  <a className="logo" href="/">
                    <img src={logo} alt="LOGO"   />
                  </a>
                  <ul className="nav-btn-list">
                      <li>
                    <Link to="/">
                        {/* <a
                          href="#"
                          //  onClick={() => navigate("/")}
                        > */}
                          HOME
                        {/* </a> */}
                    </Link>
                      </li>
                    {context.isAuthenticated ? (
                      <>
                          <li className="login-item serch">
                        <Link to="/dashboard">
                            {/* <a style={{ color: "#fff" }}> */}
                              {/* <a href="#" onClick={() => navigate("/dashboard")}> */}
                              DASHBOARD
                            {/* </a> */}
                        </Link>
                          </li>
                          <li>
                        <Link to="/history">
                            {/* <a href="/history" style={{ color: "#fff" }}> */}
                              HISTORY
                            {/* </a> */}
                        </Link>
                          </li>

                        <li>
                          <a
                            style={{ color: "white", cursor: "pointer" }}
                            onClick={Logout}
                          >
                            LOGOUT
                          </a>
                        </li>
                      </>
                    ) : (
                      <>
                        <li className="login-item serch">
                          <Link to="/signUp">
                          {/* <a
                            href="/signUp"
                            // onClick={modalStatus}
                            style={{}}
                          > */}
                            SignUp
                          {/* </a> */}
                          </Link>
                          <Link to="/login">                         
                           {/* <a
                            href="/login"
                            // onClick={modalStatus}
                          > */}
                            Login
                          {/* </a> */}
                          </Link>

                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {/* Create Account Modal */}
      <Modal
        show={createaccountmodalshow}
        onHide={() => CreateaccountModalShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {accountmodal ? (
          <>
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                <h5>SIGNUP</h5>
                <button
                  type="button"
                  className="close"
                  onClick={accountmodalStatus}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <input
                type="text"
                className="form-control firstname"
                placeholder="Enter first name"
                // value={email}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
              <input
                type="text"
                className="form-control lastname"
                placeholder="Enter last name"
                // value={email}
                onChange={(e) => {
                  setLastname(e.target.value);
                }}
              />
              <input
                type="text"
                className="form-control email"
                placeholder="Enter your email address"
                // value={email}
                onChange={(e) => {
                  setemail(e.target.value);
                }}
              />
              <input
                type="password"
                className="form-control password"
                placeholder="Enter your password"
                // value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <input
                type="password"
                className="form-control password"
                placeholder="Confirm password"
              />
              <div class="full-div text-center">
                <a
                  onClick={() => {
                    // SignUp();
                  }}
                  href="#"
                  class="reg-btn blue full"
                >
                  SIGN UP
                </a>
              </div>
            </Modal.Body>
          </>
        ) : (
          <>
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                <h5>Choose Password</h5>
                <button
                  type="button"
                  className="close"
                  onClick={accountmodalStatus}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <input
                type="password"
                class="form-control password"
                placeholder="Choose Password"
              />
              <input
                type="password"
                className="form-control password"
                placeholder="Confirm password"
              />
              <button className="form-submit-btn">Continue</button>
            </Modal.Body>
          </>
        )}
      </Modal>
      {/* Create Account Modal */}

      {/* Login Modal */}
      <Modal
        show={loginmodalshow}
        onHide={() => loginModalShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {signinModal ? (
          <>
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                <h5>LOGIN</h5>
                <button
                  type="button"
                  className="close"
                  onClick={loginmodalStatusclose}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <input
                type="text"
                className="form-control email"
                placeholder="Enter your email address"
                onChange={(e) => {
                  setLogEmail(e.target.value);
                }}
              />
              <input
                type="password"
                className="form-control password"
                placeholder="Enter your Password"
                onChange={(e) => {
                  setLogPassword(e.target.value);
                }}
              />
              <div className="full-div text-right">
                <ul className="forgt-sign-list">
                  <li>
                    <button
                      className="form-submit-btn"
                      onClick={() => SignInModalShow(false)}
                    >
                      Forgot Password
                    </button>
                  </li>
                  <li>
                    <button className="form-submit-btn" onClick={modalStatus2}>
                      SignUp
                    </button>
                  </li>
                </ul>
              </div>
              <div className="full-div text-center">
                <button
                  href="#"
                  className="reg-btn blue full"
                  // onClick={Login}
                >
                  LOGIN
                </button>
              </div>
            </Modal.Body>
          </>
        ) : (
          <>
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                <h5>Forgot Password</h5>
                <button
                  type="button"
                  className="close"
                  onClick={loginmodalStatusclose}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <input
                type="password"
                className="form-control password"
                placeholder="New Password"
              />
              <input
                type="password"
                className="form-control password"
                placeholder="Confirm password"
              />
              <div className="full-div text-center">
                <a href="#" className="reg-btn blue full">
                  Submit
                </a>
              </div>
            </Modal.Body>
          </>
        )}
      </Modal>
      {/* Login Modal */}
    </>
  );
}
export default Header;
