import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AppContext from "../context/AppContext";
import Pagination from "react-bootstrap/Pagination";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import "./Style.css"

function SessionIdHistory(props) {
  const [order, setOrder] = useState([]);
  const [card, setCard] = useState();
  const [page, setPage] = useState(0);
  const context = useContext(AppContext);

  const location = useLocation();
  useEffect(() => {
    console.log(props, " props");
    console.log(location, " useLocation Hook");
    console.log(location, " useLocation Hook");
    const data = location.state?.data;
    console.log(data, " dataHook");

    const { setIsAuthenticated, setIsLoading } = context;

    const token = localStorage.getItem("AuthentificationToken");
    axios
      .get(
        ` https://ijzbot-api.ibt-learning.com/api/v1/Session/GetSessions?SessionId=${data}&SelectOrders=true`,

        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((resp) => {
        console.log(resp.data.data[0], "getsession123");
        setOrder(resp.data.data[0].orders);
        setCard(resp.data.data[0]);
        if (localStorage.getItem("AuthentificationToken")) {
          setIsAuthenticated(true);
        }
      })
      .catch((error) => {});
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <section className="dashboard-landing-pnl container-fluid stickyFooter">
        <div className="row">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h1>Session Details</h1>
                <div className="flex-div run-btns-pnl">
                  <ul className="dashboard-list">
                    <li>
                        <>
                          {/* <a
                            href="#"
                            className="reg-btn blue full"
                            onClick={() => {
                              navigate("/bot");
                            }}
                          >
                            Running
                          </a> */}
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span>Symbol {card?.sessionSymbol}
                              </span>
                            <span>Safty Order {card?.maxSo}
                              </span>
                            <span
                            >
                              Base Amount {card?.baseOrderAmount} USDT
                            </span>
                            <span
                            // style={{ whiteSpace: "nowrap" }}
                            >
                              Max Amount {card?.maxUsageAmount} USDT
                            </span>
                            <span
                            // style={{ whiteSpace: "nowrap" }}
                            >
                              Profit {card?.takeProfitPercentage} %
                            </span>
                          </div>
                        </>
                       
                        
                       
                    </li>
                    <li className="volume-list">
                          <div>
                            <p className="reg-btn blue full">
                              Total volume bought{" "}
                              <span>
                                {card?.totalVolBought.toFixed(4)} USDT
                              </span>
                            </p>
                            <p className="reg-btn blue full">
                              Total volume sold{" "}
                              <span>{card?.totalVolSold.toFixed(4)} USDT</span>
                            </p>
                          </div>
                        </li>
                    
                  </ul>
                </div>
                <div className="full-div table-contnr">
                  {/* <TableContainer component={Paper} className="Text-white"> */}
                    <table>
                      <caption>Orders</caption>
                      <thead>
                        <tr>
                          <td>ID</td>
                          <td>SIDE</td>
                          <td>ORDER TYPE</td>
                          <td>STATUS</td>
                          <td>RATE</td>
                          <td>AMOUNT</td>
                          <td>VOLUME</td>
                          <td>CREATED</td>
                        </tr>
                      </thead>
                      <tbody>
                        {order
                          ?.sort((a, b) => b.orderId - a.orderId ).map((ord, ind) => {

                            return (
                              <tr>
                                <td>{ord?.orderId}</td>
                                <td>
                                  {ord?.orderSide == 1
                                    ? "Sell"
                                    : ord?.orderSide == 2
                                    ? "Buy"
                                    : null}
                                </td>
                                <td>
                                  {ord?.orderType == 1
                                    ? "Base"
                                    : ord?.orderType == 2
                                    ? "Safety"
                                    : ord?.orderType == 3
                                    ? "Profit"
                                    : null}
                                </td>
                                <td>
                                  {ord?.orderStatus == 0
                                    ? "New"
                                    : ord?.orderStatus == 2
                                    ? "Filled"
                                    : ord?.orderStatus == 3
                                    ? "Cancelled"
                                    : null}
                                </td>
                                <td>{ord?.orderLimitRate}</td>
                                <td>{ord?.orderAmount}</td>
                                <td>{ord?.orderAmountUsdt} USDT</td>
                                <td>
                                  {ord?.createdAt.split("T")[0]}{" "}
                                  {ord?.createdAt.split("T")[1].split(".")[0]}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    {/* <div className="Text-white1">
                      <TablePagination
                        component="div"
                        count={order.length}
                        rowsPerPage={10}
                        rowsPerPageOptions={[]}
                        page={page}
                        onChangePage={handleChangePage}
                      />
                    </div> */}
                  {/* </TableContainer> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SessionIdHistory;
