import axios from "axios";
import React, { useContext, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { signUp } from "../services/services/account-services";
import { Eye, EyeOff } from "react-feather";
import "./Style.css";

import { useNavigate } from "react-router-dom";
import AppContext from "../context/AppContext";

function SignUp() {
  const [firstName, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [firstErrorMessage, setFirstErrorMessage] = useState("");
  const [lastErrorMessage, setLastErrorMessage] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [passowErrorMessage, setPasswordErrorMessage] = useState("");
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
    useState("");
  const { addToast } = useToasts();

  const context = useContext(AppContext);

  const navigate = useNavigate();

  const [hiddenConfirmPassword, setConfirmHiddenPassword] = useState();
  const [hiddenPassword, setHiddenPassword] = useState();

  const toggleShow = () => {
    setConfirmHiddenPassword(!hiddenConfirmPassword);
  };

  const toggleShow1 = () => {
    setHiddenPassword(!hiddenPassword);
  };

  const handleChange = (e) => {
    var nameRegex = /^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/;
    var emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var passwordRegx =
      /^(?=.*\d)(?=.*[!@#.$%_^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    var countryRegex = /^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/;
    // var addrs = new RegExp("^[a-zA-Z0-9\s,'-]*$")
    var addrs = /^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/;

    const { name, value } = e.target;

    if (name === "firstname") {
      if (!value.match(nameRegex)) {
        setFirstErrorMessage("Enter Valid First Name");
        setFirstName(value);
      } else {
        setFirstName(value);
        setFirstErrorMessage("");
      }
    } else if (name === "lasttname") {
      if (!value.match(nameRegex)) {
        setLastErrorMessage("Enter Valid Last Name");
        setLastName(value);
      } else {
        setLastName(value);
        setLastErrorMessage("");
      }
    } else if (name === "email") {
      if (!value.match(emailReg)) {
        console.log("defaulter er");
        setEmailErrorMessage("Enter Valid Email");
        setEmail(value);
      } else {
        setEmail(value);
        setEmailErrorMessage("");
      }
    } else if (name === "password") {
      if (!value.match(passwordRegx)) {
        setPasswordErrorMessage(
          "Password must Contain a Capital letter a number a special character and min length 8 character"
        );
        setPassword(value);
      } else {
        setPassword(value);
        setPasswordErrorMessage("");
      }
    } else if (name === "confirmpassword") {
      if (value !== password) {
        setConfirmPasswordErrorMessage("Password Does'nt amtch");
        setConfirmPassword(value);
      } else {
        setConfirmPassword(value);
        setConfirmPasswordErrorMessage("");
      }
    }
  };

  const SignUp = () => {
    if (
      firstName !== "" &&
      lastname !== "" &&
      password !== "" &&
      confirmPassword !== "" &&
      !firstErrorMessage &&
      !lastErrorMessage &&
      !passowErrorMessage &&
      !confirmPasswordErrorMessage
    ) {
      const { setIsLoading } = context;
      setIsLoading(true);
      let data = {
        firstName: firstName,
        lastName: lastname,
        email: email,
        password: password,
      };
      // axios
      //   .post("http://69.60.115.140:4002/api/v1/Account/signup", data, {})
      signUp(data)
        .then((response) => {
          setIsLoading(false);

          navigate("/verifyemail");
        })
        .catch((error) => {
          setIsLoading(false);

          console.log(error.response.data.message, "umer");
          addToast(`${error.response.data.message}`, {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 2000,
          });
        });
    } else {
      addToast("Please fill all the fields correctly", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });
    }
  };

  return (
    <div className="row stickyFooter">
      <div className="container">
        {/* <div className="row"> */}

        <div className="col-lg-12 col-sm-12 col-md-12 col-12">
          <div
            style={{
              marginTop: "300px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ width: "30vw" }}>
              <div>
                <input
                  name="firstname"
                  maxlength="25"
                  type="text"
                  className="form-control firstname"
                  placeholder="Enter first name"
                  value={firstName}
                  onChange={(e) => {
                    handleChange(e);

                    // setFirstName(e.target.value);
                  }}
                />
                <span className="text-danger">{firstErrorMessage}</span>
              </div>

              <input
                name="lasttname"
                type="text"
                maxlength="25"
                className="form-control lastname"
                placeholder="Enter last name"
                value={lastname}
                onChange={(e) => {
                  handleChange(e);

                  // setLastname(e.target.value);
                }}
              />
              <span
                className="text-danger"
                // style={{color:"red"}}
              >
                {lastErrorMessage}
              </span>

              <input
                name="email"
                type="text"
                className="form-control email"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => {
                  handleChange(e);

                  // setemail(e.target.value);
                }}
              />
              <span className="text-danger">{emailErrorMessage}</span>

              <div style={{ position: "relative" }}>
                <input
                  name="password"
                  type={hiddenPassword ? "text" : "password"}
                  className="form-control "
                  placeholder="Enter your "
                  value={password}
                  onChange={(e) => {
                    handleChange(e);
                    // setPassword(e.target.value);
                  }}
                />

                <span className="text-danger">{passowErrorMessage}</span>
                <div
                  className="form-control-position"
                  style={{
                    position: "absolute",
                    top: "6px",
                    right: "10px",
                    zIndex: 5,
                    width: "auto",
                    display: "inline-block",
                  }}
                >
                  <span className="" onClick={toggleShow1}>
                    {hiddenPassword === true ? (
                      <EyeOff
                        style={{ cursor: "pointer", color: "white" }}
                        size={18}
                      />
                    ) : (
                      <Eye
                        style={{ cursor: "pointer", color: "white" }}
                        size={18}
                      />
                    )}
                  </span>
                </div>
              </div>
              <div style={{ position: "relative" }}>
                <input
                  name="confirmpassword"
                  type={hiddenConfirmPassword ? "text" : "password"}
                  className="form-control "
                  placeholder="Confirm password"
                  value={confirmPassword}
                  onChange={(e) => {
                    handleChange(e);
                    // setConfirmPassword(e.target.value);
                  }}
                />
                <span className="text-danger">
                  {confirmPasswordErrorMessage}
                </span>
                <div
                  className="form-control-position"
                  style={{
                    position: "absolute",
                    top: "6px",
                    right: "10px",
                    zIndex: 5,
                    width: "auto",
                    display: "inline-block",
                  }}
                >
                  <span className="" onClick={toggleShow}>
                    {hiddenConfirmPassword === true ? (
                      <EyeOff
                        style={{ cursor: "pointer", color: "white" }}
                        size={18}
                      />
                    ) : (
                      <Eye
                        style={{ cursor: "pointer", color: "white" }}
                        size={18}
                      />
                    )}
                  </span>
                </div>
              </div>

              <div className="full-div text-center">
                <a
                  onClick={() => {
                    SignUp();
                  }}
                  href="#"
                  className="reg-btn blue full"
                >
                  SIGN UP
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}

export default SignUp;
