import React from 'react'

function VerifyEmail() {
  return (
    <section className='trading-bot container-fluid'>
    <div className='row'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-8 offset-lg-2'>
                    <div className='iinjaz-bot-form full-div'>
                        <div className='iinjaz-bot-head full-div'>
                            <h1>Please Verify from your email</h1>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section >  )
}

export default VerifyEmail