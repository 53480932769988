import axios from 'axios';
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import "./Style.css"

function ForgotPasswordCode() {
    const location = useLocation()
   

    useEffect(()=>{
        let uid =location.pathname.split('/')[2];
        // verifyEmail();
    },[])

    // const verifyEmail =()=>{
    //     let uid =location.pathname.split('/')[2];
    //     console.log(location.pathname.split('/')[2],"location");


    //     axios
    // .get(`http://69.60.115.140:4002/api/v1/Account/verifyemail/${uid}`, {
      
    // })
    // .then((response) => {

     
    // })
    // .catch((error) => {
     
    //   console.log(error.response.data.Error.ErrorMessage, "umer");
     
    // });
    // }
  return (
    <section className='trading-bot container-fluid stickyFooter'>
    <div className='row'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-8 offset-lg-2'>
                    <div className='iinjaz-bot-form full-div'>
                        <div className='iinjaz-bot-head full-div'>
                            <h1>Link Send to your email please Verify</h1>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section >  )
}

export default ForgotPasswordCode