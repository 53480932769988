import axios from "axios";

 const customAxios = () => {
    // axios instance for making requests
    const axiosInstance = axios.create();
    const token = localStorage.getItem('AuthentificationToken');
    axiosInstance.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    // axiosInstance.defaults.headers.common={'Accept': 'application/json, text/plain, */*'}
    // request interceptor for adding token
    // axiosInstance.interceptors.request.use((req) => {
    //         return req;
    //     },
    //     (err) => {
    //         return Promise.reject(err);
    //     }
    // );

    axiosInstance.interceptors.response.use((res) => {
            return res;
        },
        (err) => {

            return Promise.reject(err);
        }
    );

    return axiosInstance;
};

export default customAxios;