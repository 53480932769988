import axios from "axios";
import React, { useContext, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AppContext from "../context/AppContext";
import { useToasts } from "react-toast-notifications";
import { Eye, EyeOff } from "react-feather";
import "./Style.css"

import { resetPassword } from "../services/services/account-services";

function ChangePassword() {
  let location = useLocation();
  const { addToast } = useToasts();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [passowErrorMessage, setPasswordErrorMessage] = useState("");
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
    useState("");

  const [hiddenConfirmPassword, setConfirmHiddenPassword] = useState();
  const [hiddenPassword, setHiddenPassword] = useState();

  const toggleShow = () => {
    setConfirmHiddenPassword(!hiddenConfirmPassword);
  };

  const toggleShow1 = () => {
    setHiddenPassword(!hiddenPassword);
  };

  const navigate = useNavigate();
  const context = useContext(AppContext);
  const handleChange = (e) => {
    var passwordRegx =
      /^(?=.*\d)(?=.*[!@#.$%_^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    const { name, value } = e.target;
    if (name === "password") {
      if (!value.match(passwordRegx)) {
        setPasswordErrorMessage(
          "Password must Contain a Capital letter a number a special character and min length 8 character"
        );
        setPassword(value);
      } else {
        setPassword(value);
        setPasswordErrorMessage("");
      }
    } else if (name === "confirmpassword") {
      if (value !== password) {
        setConfirmPasswordErrorMessage("Password Does not amtch");
        setConfirmPassword(value);
      } else {
        setConfirmPassword(value);
        setConfirmPasswordErrorMessage("");
      }
    }
  };

  const ConfirmPasswordfun = () => {
    if (
      password !== "" &&
      confirmPassword !== "" &&
      !passowErrorMessage &&
      !confirmPasswordErrorMessage
    ) {
      let uid = location.pathname.split("/")[2];
      console.log(location.pathname.split("/")[2], "location");
      const { setIsLoading } = context;
      setIsLoading(true);
      let data = {
        id: uid,
        // id:"96e89f2e-86f5-41ed-975f-bcef1e74b732",
        newPassword: password,
      };
      // axios
      //   .post(" https://ijzbot-api.ibt-learning.com/api/v1/Account/resetpassword", data, {})
      resetPassword(data);
      setIsLoading(false)
        .then((response) => {
          navigate("login");
          addToast("Password succesfully Change login with new password", { 
            appearance: "succes",
            autoDismiss: true,
            autoDismissTimeout: 2000,
          });
        })
        .catch((error) => {
          setIsLoading(false);
          addToast("Something Went Wrong", {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 2000,
          });
        });
    } else {
      addToast("Please fill all the fields correctly", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });
    }
  };
  return (
    <div className="row stickyFooter">
      <div className="container">
        {/* <div className="row"> */}

        <div className="col-lg-12 col-sm-12 col-md-12 col-12">
          <div
            style={{
              marginTop: "300px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ width: "30vw" }}>
              <div style={{ position: "relative" }}>
                <input
                  name="password"
                  type={hiddenPassword ? "password" : "text"}
                  className="form-control password"
                  placeholder="Choose Password"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                <span className="text-danger">{passowErrorMessage}</span>
                <div
                  className="form-control-position"
                  style={{
                    position: "absolute",
                    top: "6px",
                    right: "10px",
                    zIndex: 5,
                    width: "auto",
                    display: "inline-block",
                  }}
                >
                  <span className="" onClick={toggleShow1}>
                    {hiddenPassword === true ? (
                      <EyeOff
                        style={{ cursor: "pointer", color: "white" }}
                        size={18}
                      />
                    ) : (
                      <Eye
                        style={{ cursor: "pointer", color: "white" }}
                        size={18}
                      />
                    )}
                  </span>
                </div>
              </div>
              <div style={{ position: "relative" }}>
                {" "}
                <input
                  name="confirmpassword"
                  type={hiddenConfirmPassword ? "password" : "text"}
                  className="form-control password"
                  placeholder="Confirm password"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                <span className="text-danger">
                  {confirmPasswordErrorMessage}
                </span>
                <div
                  className="form-control-position"
                  style={{
                    position: "absolute",
                    top: "6px",
                    right: "10px",
                    zIndex: 5,
                    width: "auto",
                    display: "inline-block",
                  }}
                >
                  <span className="" onClick={toggleShow}>
                    {hiddenConfirmPassword === true ? (
                      <EyeOff
                        style={{ cursor: "pointer", color: "white" }}
                        size={18}
                      />
                    ) : (
                      <Eye
                        style={{ cursor: "pointer", color: "white" }}
                        size={18}
                      />
                    )}
                  </span>
                </div>
                <button
                  style={{ margin: "0 auto", display: "block" }}
                  href="#"
                  className="reg-btn blue full"
                  onClick={ConfirmPasswordfun}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}

export default ChangePassword;
