import React, { createContext, useState } from 'react';

const AppContext = createContext(null);

const AppProvider = (props) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);



    return (
        <AppContext.Provider
            value={{
                isAuthenticated, 
                setIsAuthenticated,
                isLoading, 
                setIsLoading,
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
}

export default AppContext;

export { AppProvider };