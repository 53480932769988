// import { getTwoFaBarCode } from "./sevice/account-service";

const networkEndpoints = {
  login: 'v1/Account/login',
  signup: 'v1/Account/signup',
  getactivesession:`v1/Session/GetActiveSession`,
  getsessions:`v1/Session/GetSessions`,
  stopsessions:`v1/Session/StopActiveSession`,
  cancelactivesession:`v1/Session/CancelActiveSession`,
  forgetpassword: `v1/Account/forgotpassword/`,
  verifyemail:`v1/Account/verifyemail/`,
  resetpassword:`v1/Account/resetpassword`

};

export default networkEndpoints;