import axios from 'axios';
import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import  {verifyEmail} from '../services/services/account-services'
import "./Style.css"


function AccountCreated() {
    const location = useLocation()

   

    useEffect(()=>{
        VerifyEmail();
    },[])

    const VerifyEmail =()=>{
        let uid =location.pathname.split('/')[2];
        console.log(location.pathname.split('/')[2],"location");


        // axios
    // .get(` https://ijzbot-api.ibt-learning.com/api/v1/Account/verifyemail/${uid}`)
    verifyEmail(uid)
    .then((response) => {

     
    })
    .catch((error) => {
     
      console.log(error.response.data.Error.ErrorMessage, "umer");
     
    });
    }
  return (
    <section className='trading-bot container-fluid stickyFooter'>
    <div className='row'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-8 offset-lg-2'>
                    <div className='iinjaz-bot-form full-div'>
                        <div className='iinjaz-bot-head full-div'>
                            <h1>Account Created Successfully</h1>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section >  )
}

export default AccountCreated