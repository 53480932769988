import React from "react";
import logo from "./logo.svg";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import Header from "./pages/header";
import Home from "./pages/home";
import Dashboard from "./pages/dashboard";
import Bot from "./pages/bot";
import AccountCreated from "./pages/accountCreated";
import Footer from "./pages/footer";
import NavBar from "./NavBar";
import "./App.scss";
import SignUp from "./pages/SignUp";
import Logout from "./pages/Logout";
import Login from "./pages/Login";
import ForgotPasswordCode from "./pages/ForgotPasswordCode";
import ForgotPassword from "./pages/Forgotpassword";
import ChangePassword from "./pages/ChangePassword";
import VerifyEmail from "./pages/VerifyEmail";
import SessionIdHistory from "./pages/SessionIdHistory";
import {
  Route,
  Links,
  Switch,
  BrowserRouter,
  Routes,
  Router,
} from "react-router-dom";


import PrivateRoute from "./PrivateRoute";
import Loader from "./pages/Loader";
import History from "./pages/History";

function App() {
  return (
    <div className="App">
      
      <Header />
      <Loader
        style={{
          // flex: 2,
        }}
      >
      <Routes>
        <Route path="/" element={<Home />} />

        
        {/* <Route path="/*" element={<PrivateComponent />}>
          <Route path="privateroute1" element={<Privateroute1 />} />
          <Route path="privateroute2" element={<Privateroute2 />} />
        </Route> */}

        <Route path="/account-confirmation/:id" element={<AccountCreated />} />
        <Route path="/signUp" element={<SignUp />} />
        <Route path="/verifyemail" element={<VerifyEmail />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/forgetPassword" element={<ForgotPassword />} />
        <Route path="/forgetPasswordCode" element={<ForgotPasswordCode />} />
        <Route path="/account-Recovery/:id" element={<ChangePassword />} />
        {/* <Route path="/*" element={<PrivateRoute />}> */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/bot" element={<Bot />} />
          <Route path="/history" element={<History />} />
          <Route path="/sessionIdHistory" element={<SessionIdHistory />} />
        {/* </Route> */}
      </Routes>
      </Loader>
      <Footer />
    

      {/* </BrowserRouter> */}
    </div>
  );
}

export default App;
