import axios from "axios";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AppContext from "../context/AppContext";
import { forgetPassword } from "../services/services/account-services";
import "./Style.css"

const Forgotpassword=()=> {
  const [userInfo, setUserInfo] = useState();
  const navigate = useNavigate();
  const context = useContext(AppContext);

  const forgetPassword1 = () => {
    const { setIsLoading } = context;
    setIsLoading(true)
    axios
    .get(` https://ijzbot-api.ibt-learning.com/api/v1/Account/forgotpassword/${userInfo}` )

    // forgetPassword(userInfo)
      .then((response) => {
        setIsLoading(false)

        console.log(response.data.data.accessToken, "Tokennnn");
        navigate("/forgetPasswordCode");
      })
      .catch((error) => {
        setIsLoading(false)

        console.log(error.response.data.Error.ErrorMessage);
      });
  };

  return (
    <div className="row stickyFooter">
      <div className="container">
        {/* <div className="row"> */}

        <div className="col-lg-12 col-sm-12 col-md-12 col-12">
          <div
            style={{
              marginTop: "300px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ width: "30vw" }}>
              <input
                type="text"
                className="form-control email"
                placeholder="Enter your email address"
                onChange={(e) => {
                  setUserInfo(e.target.value);
                }}
              />

              {/* <div className="full-div text-right">
                <ul className="forgt-sign-list" style={{display:"flex",flexDirection:"between"}}>
                <Link to=" /login">
                  <li style={{ cursor: "pointer", color: "white" }}>Login</li>
                </Link>
                </ul>
              </div> */}
              <div className="full-div text-center">
                <button
                  href="#"
                  className="reg-btn blue full"
                  onClick={forgetPassword1}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}

export default Forgotpassword;
