import React from 'react';

function Footer() {
    return (
        <>
            <footer className="container-fluid">
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <p>
                                All Rights Reserved by &copy; AMMAG
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer >
        </>
    );
}
export default Footer;